import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import Templates from './data/templates';
import type { ISharedTemplateProps } from './types';
import { AVAILABLE_THEMES_TYPE } from '@/styles/constants';

const TemplateMapper = ({
  components,
  copy,
  recipeCopy,
  difficultyCopy,
  searchItemLabels,
  metadata,
  globalMetadata,
  primarySubBrandColor = '',
  secondarySubBrandColor = '',
  pageType = '',
  formSubmitCallback,
  searchData,
}: ISharedTemplateProps) => {
  const { theme, themeName } = useGlobalContext();

  return components?.map((templateProps, index: number) => {
    const isExtensionComponent = templateProps.__typename === 'ExtensionComponents';
    /**
     * if mapper needs to render an extension component like GenericAccordionTopRated
     * the actual __typename is contained in the data property.
     * E.g. GenericAccordionTopRated:
     * {
     *  __typename: 'ExtensionComponents',
     * data: {
     *    __typename: 'GenericAccordionTopRated',
     *    id: 123,
     *    accordionItems: [...]
     *  }
     * }
     */
    const componentData = isExtensionComponent ? templateProps.data : templateProps;
    const componentType = componentData.__typename ?? '';
    const Template = componentType && Templates[componentType];

    if (!Template) return null;

    return (
      <>
        {'anchorNav' in componentData && componentData?.anchorNav && (
          <div
            className="spacer-anchor"
            id={componentData.anchorNav.navigationTitle?.split(' ')?.join('-')}
            style={{ scrollMarginTop: '150px' }}
          />
        )}

        <Template
          key={componentData.id}
          templateProps={componentData as any} // needs proper casting to componentData that is currently about to be rendered - https://stackoverflow.com/questions/67702241/render-dynamically-react-components-with-typescript
          components={components}
          copy={copy}
          recipeCopy={recipeCopy ?? []}
          difficultyCopy={difficultyCopy ?? {}}
          searchItemLabels={searchItemLabels}
          metadata={metadata}
          globalMetadata={globalMetadata}
          primarySubBrandColor={primarySubBrandColor}
          secondarySubBrandColor={secondarySubBrandColor}
          pageType={pageType}
          formSubmitCallback={formSubmitCallback}
          searchData={searchData}
          templateIndex={index}
          theme={theme}
          themeName={themeName as AVAILABLE_THEMES_TYPE}
        />
      </>
    );
  });
};

export default TemplateMapper;
