import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import type { ISearchOverlay } from 'components/HeaderNavigation/components/MobileNavigation';
import type { DictionaryItem } from 'service/dictionary/dictionaryService';
import { TrackingService } from 'service/tracking/trackingService';
import TemplateMapper from 'templates/TemplateMapper';
import type { IGlobalMetaData } from 'types/GlobalMetadata';
import getSearchClient from '../../server/repository/algolia/client';

const UpArrow = dynamic(() => import('components/UpArrow').then((m: any) => m.UpArrow), {
  ssr: true,
}) as any;

const AnchorNavigation = dynamic(
  () => import('components/AnchorNavigation').then((m) => m.AnchorNavigation),
  { ssr: true },
);

const ClientIndexPage = ({
  data,
  dynamicPageComponents,
  searchOverlayData,
  globalMetadata,
  difficultyCopy,
  recipeCopy,
  searchItemLabels,
}: {
  data: any;
  dynamicPageComponents: any;
  searchOverlayData: ISearchOverlay;
  difficultyCopy: Record<string, string>;
  recipeCopy: DictionaryItem[];
  globalMetadata: IGlobalMetaData;
  searchItemLabels: Record<string, string>;
}) => {
  const page = data.genericPages?.[0];
  const cg1 = page?.contentGroupingTrackingValue;
  const cg2 = page?.contentGroupingTrackingValues;
  const [searchOverlayDataExtended, setSearchOverlayDataExtended] = useState(searchOverlayData);

  useEffect(() => {
    if (!searchOverlayDataExtended.searchClient) {
      const algoliaClient = getSearchClient();
      setSearchOverlayDataExtended({
        ...searchOverlayDataExtended,
        searchClient: algoliaClient,
      });
    }
  }, [searchOverlayData]);

  useEffect(() => {
    if (cg1) {
      if (cg2) {
        TrackingService.contentGroups({
          cg1: cg1.replace(/_/g, ' '),
          cg2: cg2.replace(/_/g, ' '),
        });
      } else {
        TrackingService.contentGroups({
          cg1: cg1.replace(/_/g, ' '),
        });
      }
    }
  }, []);
  const anchorNavigations = page?.anchorNavigationList?.anchorNavigations;
  return (
    <>
      {anchorNavigations?.length > 0 && <AnchorNavigation anchorNavigations={anchorNavigations} />}
      {dynamicPageComponents && (
        <TemplateMapper
          components={dynamicPageComponents}
          copy={page?.dictionary?.dictionaryItems}
          metadata={page?.metaData}
          globalMetadata={globalMetadata}
          difficultyCopy={difficultyCopy}
          recipeCopy={recipeCopy}
          searchItemLabels={searchItemLabels}
          pageType="homepage"
          searchData={searchOverlayDataExtended}
        />
      )}
      <UpArrow />
    </>
  );
};

export default ClientIndexPage;
